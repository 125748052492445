<template>
  <div class="game-item" :class="{ maintain: item.maintain }">
    <div class="item game-item__wrapper">
      <CommonBaseImg
        class="game-item__wrapper--image"
        :src="item.img"
        :alt="item.name"
        :width="`${autoWidth ? 'auto' : '236'}`"
        :height="`${autoHeight ? 'auto' : '176'}`"
        :border-radius="`${radius ? radius : '16'}`"
        lazy
      />
      <div class="hover-wrapper">
        <div class="game-item__wrapper--popup">
          <div class="inner">
            <button @click="onClickGame(false)">{{ textPlay }}</button>
            <a v-if="item.is_demo" @click="onClickGame(true)">Chơi thử</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showName" class="game-item__name">{{ item.name }}</div>
    <div v-if="jackpotNumber && showJackpotAnimationByRoutes" class="game-item__wrapper--jackpot">
      <div class="jackpot">
        <AnimateCountUp :number="jackpotNumber" :show-coin="true" :class="[osType]" />
      </div>
    </div>
    <CommonBaseImg v-if="item.maintain" class="game-item__maintain" :src="getThumbImage(item)" />
    <CommonBaseImg
      v-else-if="getThumbImage(item)"
      class="game-item__maintain"
      :class="[item?.tags?.name?.toLowerCase()]"
      :src="getThumbImage(item)"
      :alt="`tags ${item.tags}`"
    />
  </div>
</template>

<script setup lang="ts">
import debounce from 'lodash/debounce'
import { storeToRefs } from 'pinia'
import AnimateCountUp from './animate-count-up.vue'
import { useAppStore } from '@/store/app'
import useNavigationGame from '~/composables/useNavigationGame'
import { IGameItem } from '~/types/common'
import CommonBaseImg from '~/components/common/base-img.vue'

const { $pinia, $device } = useNuxtApp()
const store = useAppStore($pinia)
const { jackpot } = storeToRefs(store)
const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
const props = withDefaults(
  defineProps<{
    item: IGameItem
    showCoin?: boolean
    showName?: boolean
    isPlayNow?: boolean
    autoWidth?: boolean
    autoHeight?: boolean
    radius?: string
    textPlay?: string
  }>(),
  {
    showCoin: false,
    showName: false,
    isPlayNow: true,
    autoWidth: false,
    autoHeight: false,
    radius: '8',
    textPlay: 'Chơi Ngay'
  }
)

const route = useRoute()
const osType = ref()

const jackpotNumber = computed<number>(() => {
  const keyJackpot: string = props.item.partner_game_id ?? ''
  const value: Record<string, number> = jackpot.value
  return value[keyJackpot] ?? 0
})

const onClickGame = debounce((isDemo: boolean) => {
  navigationCheckLoggedInAndOpenGame(props.item, undefined, isDemo)
}, 50)

const getThumbImage = (item: IGameItem) => {
  if (item.tags?.img) {
    return item.tags.img
  }
  return ''
}

const showJackpotAnimationByRoutes = computed(() => {
  const {
    params: { type },
    name
  } = route
  const pages = ['slots', 'ban-ca', 'no-hu']

  return (typeof type === 'string' && pages.includes(type)) || name === 'index'
})

onMounted(() => {
  osType.value = $device.isDesktop ? 'desktop' : $device.isIos ? 'ios' : 'android'
})
</script>

<style lang="scss" scoped src="~/assets/scss/components/common/game-item.scss"></style>
